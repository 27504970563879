<script setup>
    import { computed } from 'vue';
    import { useDocumentStore } from "@/stores/DocumentStore";

    const props = defineProps({
        cartItems: null,
    });

    const store = useDocumentStore();

    const totalItems = computed(() => {
        const items = (props.cartItems && props.cartItems != 'null') ? JSON.parse(props.cartItems) : [];

        return items.length > 0 ? items.length : store.cartItems?.length;
    });
</script>

<template>
    <li v-if="totalItems > 0 || store.cartItems?.length > 0" class="nav-item me-2 me-xl-0 dropdown-notifications navbar-dropdown me-3 me-xl-1">
        <a class="nav-link hide-arrow"
            href="/checkout"
            data-bs-auto-close="outside"
            aria-expanded="false"
            data-bs-toggle="tooltip"
            data-bs-offset="0,4"
            data-bs-placement="bottom"
            data-bs-html="true"
            data-bs-original-title="Cart"
        >
            <i class="bx bx-cart bx-sm"></i>
            <span class="badge bg-danger rounded-pill badge-notifications" style="top: auto">{{ totalItems }}</span>
        </a>
    </li>
</template>
