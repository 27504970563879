export const user = '/users'
export const legalRequest = '/our-legal-requests'
export const newLegalRequest = '/our-new-legal-requests'
export const legalRequestNote = '/our-legal-requests/notes'
export const legalAssignLawyer = '/our-legal-requests/assign-legal'
export const legalRemoveLawyer = '/our-legal-requests/remove-legal'
export const post = '/posts'
export const category = '/categories'
export const location = '/locations'
export const workflow = '/workflows'
export const organisation = '/organisations'
export const orgSetting = '/organisation/settings'
export const usrAutocomplete = '/usr/autocomplete'
export const tag = '/tags'
export const collection = '/collections'
export const document = '/documents'
export const documentManagement = '/document-management'
export const order = '/orders'
export const organisationManagement = '/organisation-management'
export const chat = '/chats'
export const offerUpdate = '/offer-update'
export const makeOffer = '/make-offer'
export const cancelOffer = '/cancel-offer'
export const message = '/messages'
export const unSeenMessagesNumber = '/unseen-message-count'
export const changeMessageSeenTime = '/update-message-seen-time'
export const listMessage = '/list/message'
export const organizationMessage = '/organization/message'
export const authOrganization = '/auth-organisation'
export const cities = '/locations/cities'
export const states = '/locations/states'
export const postcode = '/locations/postcode'
export const cityAndState = '/locations/city-state'
export const ownOffers = '/our-own-offers'
export const listOffers = '/list-offers'
export const organization = '/organization'
export const mapLocation = '/map-location'
